import React from 'react';

import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout fullMenu>
    <article id="main">
      <header>
        <h2>Política de privacidad</h2>
        <p>Tratamiento de datos</p>
      </header>
      <section className="wrapper style5">
        <div className="inner">
          <h3>Políticas de privacidad</h3>
          <p>
          En Cosas Cripto, valoramos y respetamos tu privacidad. Esta Política de Privacidad describe cómo recopilamos, utilizamos y protegemos la información que nos proporcionas cuando utilizas nuestros servicios.
          </p>
          <h3>Información que recopilamos</h3>
          <p>
          Podemos recopilar información personal y no personal cuando interactúas con nuestros servicios, incluidos, entre otros, tu nombre, dirección de correo electrónico, dirección IP, información de transacciones y detalles de pago.
          </p>

          <hr />

          <h3>Tratamiento de datos</h3>
          
          <h4>Uso de la Información</h4>
            <p>
            Utilizamos la información que recopilamos para proporcionar, mantener y mejorar nuestros servicios, así como para responder a tus consultas y solicitudes. También podemos utilizar la información para fines de marketing y comunicación, siempre y cuando hayas dado tu consentimiento previo.
            </p>
          <h4>Compartir Información con Terceros</h4>
            <p>
            No compartimos tu información personal con terceros, excepto cuando sea necesario para proporcionar nuestros servicios, cumplir con la ley o proteger nuestros derechos, propiedad o seguridad.
            </p>
          <h4>Contacto</h4>
          <p> Si tienes alguna pregunta o inquietud sobre esta Política de Privacidad, no dudes en contactarnos a través de contacto@cosascripto.com</p>
        </div>
      </section>
    </article>
  </Layout>
);

export default IndexPage;
